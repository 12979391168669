import { defineStore } from 'pinia'

import { keyedBy } from '~/helpers/keyedBy.ts'
import { hitTransformations } from '~/services/hitTransformations.ts'
import { type CaseHit, type CaseHitTransformed, type CaseModelDetailed, type CaseSource, type CaseSourceName } from '~/types.ts'

export function getHitsByDataId(hits: Record<number, CaseHit>) {
  const hitsByDataId: Record<string | number, CaseHit> = {}

  for (const hit of Object.values(hits)) {
    hitsByDataId[hit.data.id] = hit
  }

  return hitsByDataId
}

export interface Source extends CaseSource {
  status: 'loading' | 'loaded' | 'error'
}

export function createHitsStore(sourceName: CaseSourceName, prefix = '') {
  return defineStore(`${prefix}${capitalizeFirst(sourceName)}`, {
    state: () => ({
      name: sourceName,
      sources: {} as Record<number, Source>,
      loading: [] as number[],
      originalHits: {} as Record<number, CaseHit>,
      selected: [] as (number | string)[],
    }),

    getters: {
      hits: (state) => {
        const hitsByDataId = getHitsByDataId(state.originalHits)

        return Object.values(state.originalHits).map((hit) => hitTransformations(hit, hitsByDataId))
      },

      positive(): CaseHitTransformed[] {
        return this.hits.filter((hit) => hit.resolution === 'positive')
      },

      negative(): CaseHitTransformed[] {
        return this.hits.filter((hit) => hit.resolution === 'negative')
      },

      unresolved(): CaseHitTransformed[] {
        return this.hits.filter((hit) => hit.resolution === 'unresolved')
      },

      /**
       * The ids of all hits that are marked as similar by another article.
       */
      similarHitIds(): Set<string> {
        if (this.name !== 'news') return new Set<string>()
        const similarIds = new Set<string>()

        for (const hit of Object.values(this.hits ?? [])) {
          hit.data.similar?.forEach((id) => similarIds.add(id))
        }

        return similarIds
      },
    },

    actions: {
      searching(source: CaseSource) {
        if (this.loading.includes(source.id)) {
          return
        }

        if (!(source.id in this.sources)) {
          this.addSource(source)
        }

        this.setLoading(source.id)
        this.removeUnresolvedHitsByDataSourceId(source.data_source_id)
      },

      async refreshCaseSource(id: number) {
        const source = this.sources[id]

        if (!source) {
          return // state was possibly reset (different case)
        }

        const { data } = await api.get<CaseSource & { hits: CaseHit[] }>(`case-sources/${source.id}`)

        if (!(id in this.sources)) {
          return // state was possibly reset (different case)
        }

        if ((data.searched_at && data.searched_at !== source.searched_at) || data.succeeded) {
          this.addSource(data)
          this.setLoaded(source.id)
          this.updateHits(data.hits)
        }
      },

      loadHitsFromCase(caseModel: CaseModelDetailed) {
        const sourceHits = caseModel.hits?.filter((hit) => hit.source === this.name) ?? []
        this.setHits(sourceHits)

        caseModel.sources.filter((source) => source.data_source.source === this.name).forEach((source) => this.addSource(source))
      },

      async resolve({ ids, resolution, comment = null, risk = null }: { ids: number[]; resolution: string; comment?: string | null; risk?: string | null }) {
        const hits = Object.values(this.hits).filter((hit) => ids.includes(hit.id))

        if (hits.length === 0) {
          return
        }

        const sources = [...new Set(hits.map((hit) => hit.data_source_id))].map((dataSourceId) =>
          Object.values(this.sources).find((source) => source.data_source.id === dataSourceId),
        ) as Source[]
        sources.forEach((source) => this.setLoading(source.id))

        try {
          const { data } = await api.patch<CaseModelDetailed>('case-hits', {
            resolution,
            comment,
            risk,
            source: this.name,
            case_id: hits[0].case_id,
            ids: hits.map((hit) => hit.id),
          })

          stores.cases.updateOpened(data)
          this.updateHits(data.hits)
          $bus.emit('hits.resolved')
        } catch (error) {
          console.error(error)
          $message.error($t('api-error'))
        } finally {
          sources.forEach((source) => this.setLoaded(source.id))
        }
      },

      loadHitsFromSearch({ source, hits }: { source: CaseSource; hits: CaseHit[] }) {
        // Adding organization sources service adds hits from new sources
        // So the source should be added in case its new
        this.addSource(source)
        this.removeUnresolvedHitsByDataSourceId(source.data_source_id)
        this.setLoaded(source.id)
        this.updateHits(hits)
      },

      addSource(source: CaseSource) {
        this.sources[source.id] = { ...copyObject(source), status: 'loaded' }
      },

      setLoading(id: number) {
        this.sources[id].status = 'loading'

        if (!this.loading.includes(id)) {
          this.loading.push(id)
        }
      },

      setLoaded(id: number) {
        const index = this.loading.indexOf(id)
        if (index >= 0) {
          this.loading.splice(index, 1)
        }

        this.sources[id].status = 'loaded'
      },

      setError(id: number) {
        const index = this.loading.indexOf(id)
        if (index >= 0) {
          this.loading.splice(index, 1)
        }

        this.sources[id].status = 'error'
      },

      setHits(hits: CaseHit[]) {
        this.originalHits = Object.freeze(keyedBy('id', hits))
      },

      updateHits(updatedHits: CaseHit[]) {
        const hits = Object.assign({}, this.originalHits)

        for (const hit of updatedHits) {
          hits[hit.id] = hit
        }

        this.originalHits = Object.freeze(hits)
      },

      removeUnresolvedHitsByDataSourceId(dataSourceId: number) {
        const hits = Object.assign({}, this.originalHits)

        for (const id in hits) {
          if (hits[id].data_source_id === dataSourceId && hits[id].resolution === 'unresolved') {
            delete hits[id]
          }
        }

        this.originalHits = Object.freeze(hits)
      },
    },
  })
}
