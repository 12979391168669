<template>
  <div class="map-container">
    <VResizeObserver @notify="resize" />
    <div :id />
  </div>
</template>

<script lang="ts">
import debounce from 'lodash-es/debounce.js'

export default defineComponent({
  props: {
    mapStyle: {
      type: String,
      default: 'mapbox://styles/mapbox/outdoors-v11?optimize=true',
    },

    options: {
      type: Object,
      default: () => ({}),
    },
  },

  emits: ['load'],

  data: () => ({
    map: null,
    id: `map-${Math.random().toString(36).substring(2, 15)}`,
  }),

  activated() {
    this.createMap()
  },

  mounted() {
    this.createMap()
  },

  beforeUnmount() {
    this.map?.remove()
  },

  methods: {
    resize: debounce(function () {
      this.map?.resize()
    }, 200),

    async createMap() {
      while (!document.getElementById(this.id)) {
        await nextFrame()
      }

      if (this.map !== null) {
        this.resize()

        return
      }

      const mapboxgl = window.mapboxgl
      mapboxgl.accessToken = $env.mapbox.token

      const map = new mapboxgl.Map({
        container: this.id,
        style: this.mapStyle,
        ...this.options,
      })
      this.map = map

      map.on('load', () => this.$emit('load', map))
    },
  },
})
</script>
